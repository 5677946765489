export default function ErrorPage({ statusCode, isGeneric = false }: { statusCode: string; isGeneric?: boolean }) {
  return (
    <div className="mt-10 flex w-full flex-col items-center justify-center gap-6 bg-mglGray50">
      <h1 className="text-center text-4xl font-medium">
        {!isGeneric && 'Erro'} {statusCode}
      </h1>
      <p className="text-center text-base font-light">Desculpe, não foi possível carregar a página solicitada!</p>
      <a
        className="flex cursor-pointer items-center justify-center rounded-[18px] border
         border-deprecatedCTBlue bg-deprecatedCTBlue px-6 py-2 text-center text-mglGray50 no-underline transition-all
          duration-300 hover:border-mglGray900 hover:bg-mglGray900"
        href="/">
        ir para a página principal
      </a>
    </div>
  );
}
